@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/inter");
body,
body * {
  font-family: "Inter" !important;
}
body {
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

/*  Display 2XL  */
.display-2xl,
.display-2xl-m,
.display-2xl-sb,
.display-2xl-b {
  font-size: 72px;
  line-height: 90px;
  font-weight: 400;
  letter-spacing: -1.44px;
}
.display-2xl-m {
  font-weight: 500;
}
.display-2xl-sb {
  font-weight: 600;
}
.display-2xl-b {
  font-weight: 700;
}
/*  Display XL  */
.display-xl,
.display-xl-m,
.display-xl-sb,
.display-xl-b {
  font-size: 60px;
  line-height: 72px;
  font-weight: 400;
  letter-spacing: -1.2px;
}
.display-xl-m {
  font-weight: 500;
}
.display-xl-sb {
  font-weight: 600;
}
.display-xl-b {
  font-weight: 700;
}
/*  Display LG  */
.display-lg,
.display-lg-m,
.display-lg-sb,
.display-lg-b {
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;
  letter-spacing: -0.96px;
}
.display-lg-m {
  font-weight: 500;
}
.display-lg-sb {
  font-weight: 600;
}
.display-lg-b {
  font-weight: 700;
}
/*  Display MD  */
.display-md,
.display-md-m,
.display-md-sb,
.display-md-b {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  letter-spacing: -0.72px;
}
.display-md-m {
  font-weight: 500;
}
.display-md-sb {
  font-weight: 600;
}
.display-md-b {
  font-weight: 700;
}
/*  Display SM  */
.display-sm,
.display-sm-m,
.display-sm-sb,
.display-sm-b {
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
}
.display-sm-m {
  font-weight: 500;
}
.display-sm-sb {
  font-weight: 600;
}
.display-sm-b {
  font-weight: 700;
}
/*  Display XS  */
.display-xs,
.display-xs-m,
.display-xs-sb,
.display-xs-b {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
.display-xs-m {
  font-weight: 500;
}
.display-xs-sb {
  font-weight: 600;
}
.display-xs-b {
  font-weight: 700;
}
/*  Text XL  */
.text-xl,
.text-xl-m,
.text-xl-sb,
.text-xl-b {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}
.text-xl-m {
  font-weight: 500;
}
.text-xl-sb {
  font-weight: 600;
}
.text-xl-b {
  font-weight: 700;
}
/*  Text LG  */
.text-lg,
.text-lg-m,
.text-lg-sb,
.text-lg-b {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
.text-lg-m {
  font-weight: 500;
}
.text-lg-sb {
  font-weight: 600;
}
.text-lg-b {
  font-weight: 700;
}
/*  Text MD  */
.text-md,
.text-md-m,
.text-md-sb,
.text-md-b {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.text-md-m {
  font-weight: 500;
}
.text-md-sb {
  font-weight: 600;
}
.text-md-b {
  font-weight: 700;
}
/*  Text SM  */
.text-sm,
.text-sm-m,
.text-sm-sb,
.text-sm-b {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.text-sm-m {
  font-weight: 500;
}
.text-sm-sb {
  font-weight: 600;
}
.text-sm-b {
  font-weight: 700;
}
/*  Text XS  */
.text-xs,
.text-xs-m,
.text-xs-sb,
.text-xs-b {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.text-xs-m {
  font-weight: 500;
}
.text-xs-sb {
  font-weight: 600;
}
.text-xs-b {
  font-weight: 700;
}

/*SCROLLBARS*/
.hide__scroll::-webkit-scrollbar {
  display: none;
}
.typing__animation {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  border-radius: 9999px;
}
.dot {
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;

  background: rgba(148 163 184 / 1);
  opacity: 0;
  animation: blink 1s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0.3333s;
}
.dot:nth-child(2) {
  animation-delay: 0.6666s;
}
.dot:nth-child(3) {
  animation-delay: 0.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

/* Slider */
.swiper {
  /* overflow: initial !important; */
  width: 100%;
  height: 100%;
  background-color: #faf9fe !important;
  border: 1px solid #efebfc !important;
  border-radius: 8px !important;
}
.swiper-slide {
  width: 100% !important;
  height: 224px !important;
  background-color: #faf9fe !important;
  border-radius: 8px !important;
}

@media screen and (max-width: 1000px) {
  .swiper-slide {
    height: fit-content !important;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

/* header input border */
.header-input-border-gradient {
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(
        90deg,
        rgba(204, 186, 248, 1) 0%,
        rgba(178, 147, 242, 1) 55%,
        rgba(138, 71, 224, 1) 100%
      )
      border-box;
  border-radius: 8px;
  border: 2px solid transparent;
}
body {
  background-color: #fcfcfd;
}

/* Transitions */
.expand-enter {
  max-height: 0;
  opacity: 0;
}
.expand-enter-active {
  max-height: 1000px; /* Set this to a value large enough to cover the content */
  opacity: 1;
  transition:
    max-height 500ms ease-in-out,
    opacity 500ms ease-in-out;
}
.expand-exit {
  max-height: 1000px; /* Match this value with the enter-active max-height */
  opacity: 1;
}
.expand-exit-active {
  max-height: 0;
  opacity: 0;
  transition:
    max-height 500ms ease-in-out,
    opacity 500ms ease-in-out;
}
.expand-wrapper {
  overflow: hidden;
}

/* Date Picker */
.rdp-selected {
  color: #fff !important;
  font-weight: 500 !important;
}
.rdp-selected .rdp-day_button {
  color: #fff !important;
  background-color: #8a47e0;
  border-width: 0;
  border-color: #8a47e0 !important;
}
.rdp-day {
  color: #475467 !important;
  font-size: 15px !important;
}
.rdp-today {
  font-weight: 500 !important;
  color: #56268c !important;
}
.rdp-weekday {
  color: #101828 !important;
}
.rdp-caption_label {
  color: #182230 !important;
}
.rdp-chevron {
  fill: #8a47e0 !important;
}
.rdp-disabled {
  color: #98a2b3 !important;
}
/* Time Picker */
.rc-time-picker-input {
  opacity: 0 !important;
}
.rc-time-picker-panel {
  max-width: 180px;
  width: 100% !important;
  max-height: 185px !important;
}
.rc-time-picker-panel-inner {
  max-height: 185px !important;
  height: 100% !important;
}

/* Toast */
.Toastify__toast-body {
  gap: 16px;
}
.Toastify__toast-container {
  max-width: 460px !important;
  width: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  top: 80px !important;
}
.Toastify__progress-bar--bg {
  background-color: transparent !important;
}
.Toastify__progress-bar--wrp {
  height: 2px !important;
}
.Toastify__close-button {
  color: #475467 !important;
  opacity: 1 !important;
  margin-top: 4px !important;
}

/* Tooltip */

.react-tooltip {
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
  background: #101828 !important;
  color: #ffffff !important;
  font-family: inherit !important;
}

:root {
  --ck-border-radius: 6px !important;
  --ck-color-base-border: rgb(208, 213, 221) !important
;
}

.ck-powered-by {
  display: none;
}

.ck-editor__editable {
  resize: vertical;
  overflow: auto;
  min-height: 100px;
  max-height: 400px;
}

.ck-editor__editable li {
  margin-left: 20px !important;
}

@layer base {
  html {
    @apply !overflow-visible;
  }
}

.stori-markdown-styles h1 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.75rem;
  color: #333;
}

.stori-markdown-styles h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  color: #333;
}

.stori-markdown-styles h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: #333;
}

.stori-markdown-styles h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #333;
}
.stori-markdown-styles h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #333;
}

.stori-markdown-styles code {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #333;
  white-space: pre-wrap;
}

.stori-markdown-styles strong {
  font-weight: 700;
}

.stori-markdown-styles em {
  font-style: italic;
}

.stori-markdown-styles blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1rem;
  color: #666;
  margin: 1rem 0;
}

.stori-markdown-styles hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1rem 0;
}

.stori-markdown-styles ol li {
  list-style-type: decimal;
  margin: 0.5rem 0;
  padding-left: 1rem;
  color: #333;
}

.stori-markdown-styles ul li {
  list-style-type: disc;
  margin: 0.5rem 0;
  padding-left: 1rem;
  color: #333;
}

.stori-markdown-styles ol {
  list-style-type: decimal;
  margin: 0.5rem 0;
  padding-left: 1rem;
  color: #333;
}

.stori-markdown-styles ul {
  list-style-type: disc;
  margin: 0.5rem 0;
  padding-left: 1rem;
  color: #333;
}

.stori-markdown-styles ol li,
.stori-markdown-styles ul li,
.stori-markdown-styles ol,
.stori-markdown-styles ul,
.stori-markdown-styles blockquote,
.stori-markdown-styles hr {
  margin: 0;
}

@media screen and (max-width: 900px) {
  div#hubspot-messages-iframe-container {
    visibility: hidden !important;
    display: none !important;
  }
}

.mentionsInputDiv > div:first-child {
  width: 100% !important;
  height: 100% !important;
}
.mentionsInputDiv > div:first-child > div:first-child {
  width: 100% !important;
  height: 100% !important;
  padding-inline: 12px !important;
  padding-block: 8px !important;
}
.mentionsInputDiv > div:first-child > div:first-child > span {
  font-family: "Inter" !important;
  font-size: 14px !important;
}
.mention-item {
  background-color: #d9d6fe !important;
  color: #000;
  border-radius: 6px;
  font-family: "Inter" !important;
  position: relative;
  z-index: 100;
  font-size: 14px !important;
}
.mentionsInputDiv > div > textarea {
  font-family: "Inter" !important;
  outline: none;
  border-radius: 8px;
  font-size: 14px !important;
  padding: 8px 36px 8px 12px;
  border: 1px solid #d1d5db6e;
  color: #111827;
  background-color: white !important;
}
.mentionsInputDiv [role="listbox"] [role="option"][aria-selected="true"] {
  background-color: #f2f4f7 !important;
}

::-moz-selection {
  background: #e2d9fb;
  color: #662cab;
}

::selection {
  background: #e2d9fb;
  color: #662cab;
}

@keyframes h-gradient {
  0% {
    background-position: 0 0
  }
  
  50% {
    background-position: -100% 0
  }

  100% {
    background-position: -200% 0
  }
}

.animate-h-gradient {
  transition: all 0.3s ease-in-out;
  animation: h-gradient 6s ease-in-out infinite
}